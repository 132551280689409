<template>
  <div class="table-list">
    <div>
      <ul class="table hd">
        <li class="table-row">
          <render-header
            :node="node"
            v-for="(node, index) in $slots.default"
            :key="`${index}_header`"
          ></render-header>
        </li>
      </ul>
    </div>
    <seamless-scroll
      class="recordlist"
      :style="{ height: `${height}px` }"
      v-if="data && data.length"
      :data="data"
      :class-option="classOption"
    >
      <ul class="table">
        <li
          :class="['table-row', row_index]"
          v-for="(item, row_index) in data"
          :key="item.id"
          ref="tableRows"
        >
          <render-column
            :node="node"
            :item="item"
            :row="row_index"
            v-for="(node, index) in $slots.default"
            :key="`${index}_column`"
          ></render-column>
          <div
            class="table-row-suspension"
            v-if="$scopedSlots.suspension"
            @click="clickRow(item, row_index)"
          >
            <slot
              name="suspension"
              :index="row_index"
            ></slot>
          </div>
        </li>
      </ul>
    </seamless-scroll>
    <div
      v-else
      class="empty"
      :style="{ height: `${height}px` }"
    >
      暂无数据
    </div>
  </div>
</template>

<script>
import seamlessScroll from "./vue-seamless-scroll.vue"
export default {
  components: {
    seamlessScroll,
    renderHeader: {
      functional: true,
      render(h, ctx) {
        const { node } = ctx.props
        const componentOptions = node.componentOptions || {}
        let propsData = componentOptions.propsData || {}
        let width = propsData.width ? propsData.width : "150px"
        const type = propsData.type
        if (type == "index") {
          width = "100px"
        }
        const body = h(
          "div",
          {
            class: ["table-row__cell"],
            style: {
              width,
              "text-align": propsData.align,
            },
          },
          [propsData.label]
        )
        return body
      },
    },
    renderColumn: {
      functional: true,
      render(h, ctx) {
        const { node, item, row } = ctx.props
        let propsData = node.componentOptions.propsData || {}
        let width = propsData.width ? propsData.width : "150px"
        const type = propsData.type
        let text = [item[propsData.prop]]
        let align = propsData.align ? propsData.align : "left"
        if (type == "index") {
          width = "100px"
          text = row + 1
          align = "center"
        }
        if (node.data.scopedSlots && node.data.scopedSlots.default) {
          text = node.data.scopedSlots.default({ row: item || {} })
        }
        if (!text) {
          text = "---"
        }
        const chi = h(
          "div",
          {
            class: ["table-row__cell-text"],
            style: {
              textAlign: align,
            },
          },
          [text]
        )
        const body = h(
          "div",
          {
            class: ["table-row__cell"],
            style: {
              width: width,
              display: "flex",
            },
          },
          [chi]
        )
        return body
      },
    },
  },
  props: {
    //数据列表
    data: {
      type: Array,
      default() {
        return []
      },
    },
    //整个表格的高度
    height: {
      type: Number,
      default: 170,
    },
    //一行的高度，往上滚动时如果不是标准表格，
    //位置滚动位置不出错，要修改该高度
    rowHeight: {
      type: Number,
      default: 42,
    },
    //限制移动的行数，超过该行数，则滚动
    limitMoveNum: {
      type: Number,
      default: 5,
    },
    autoPlay: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classOption() {
      return {
        singleHeight: this.rowHeight,
        autoPlay: this.autoPlay,
        limitMoveNum: this.limitMoveNum,
      }
    },
  },
  methods: {
    clickRow(row, index) {
      this.$emit("clickRow", row, index)
    },
  },
}
</script>

<style lang="less" scoped>
.recordlist {
  overflow: hidden;
}
.empty {
  font-size: 16px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #ffffff;
  text-align: center;
}

.table {
  font-size: 12px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #fff;

  .table-row {
    display: flex;
    min-height: 40px;
    padding: 10px 0;
    box-sizing: border-box;
    align-items: center;
    border-bottom: rgba(255, 255, 255, 0.1) 1px solid;
    position: relative;
    &__cell {
      white-space: nowrap;
      overflow: hidden;
      font-size: 14px;
      color: #ffffff;
      line-height: 21px;
      padding: 0 10px;
      &-text {
        overflow: hidden;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &-suspension {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  &.hd {
    .table-row {
      background: #1e5087 !important;
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      line-height: 21px;
    }
  }
}
</style>
